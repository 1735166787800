import React, { useState, useEffect, useReducer, useCallback, useContext } from 'react'
import FormIntro from './FormIntro'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import FormStep4 from './FormStep4'
import FormSuccess from './FormSuccess'
import FormUtils from '../utils/FormUtils'
//import I18n, {LangContext} from '../utils/I18n'

const FormSteps = (props) => {
    const {formActive} = props 
    //const lang = useContext(LangContext)
    const enableUploadImage = true
    const stepNames = ['Organization Information', 'Program Details', 'Workforce Directory Information', 'Program Terms']

    // --- REFERENCES ---
    const recaptchaRef = React.createRef()
    // --- END OF REFERENCES

    
    // --- STATES ---
    const [getPrepareList, setPrepareList] = useState(true)
    const [countryList, setCountryList] = useState([])
    const [optInText, setOptInText] = useState('')
    const [stepNumber, setStepNumber] = useState(0)
    const [stepProgress, setStepProgress] = useState(0)
    const [lastStepCompleted, setLastStepCompleted] = useState(0)
    const [btnNextDisabled, setBtnNextDisabled] = useState(true)
    const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [showOptIn, setShowOptIn] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [uploadedImage, setUploadedImage] = useState(null)
    // --- END OF STATES

    
    // --- REDUCERS ---
    const inputReducer = (state, action) => {
        return {...state, [action.id]: action.value}
    }

    const [inputValues, dispatchFieldChanges] = useReducer(inputReducer, {})
    const [fieldErrors, dispatchFieldErrorsChanges] = useReducer(inputReducer, {})
    // --- END OF REDUCERS


    // --- EFFECTS ---
    useEffect(() => {
        if(stepNumber > 0 && countryList.length > 0 && getPrepareList) {
            prepareFields()
            setPrepareList(false)
        }
    }, [countryList, stepNumber])

    useEffect(() => {
        setStepProgress(100 / stepNames.length * stepNumber)

        if(recaptchaToken !== '' && stepNumber < stepNames.length) {
            setRecaptchaToken('')
        }

        if(stepNumber === 0) {
            getListOfCountries()
        }

        if(stepNumber === stepNames.length) {
            setRecaptchaToken('')
            const recaptchaObj = recaptchaRef.current
            recaptchaObj.props.grecaptcha.reset()
        }

        checkToContinue(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepNumber])

    useEffect(() => {
        if(Object.keys(inputValues).length > 0) {
            checkToContinue()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues, recaptchaToken])
    // --- END OF EFFECTS


    // --- CALLBACKS (Memoized functions) ---
    const handleTdsComboboxOnChange = (e) => {
        const val = e.nativeEvent ? e.nativeEvent.target.value : e.detail
        const inputId = e.target.id

        if (inputId) {
            if (inputId === 'Submitter_Contact_Country__c') {
                const countryItem = countryList.filter( item => item.label === val)

                if (countryItem.length === 1) {
                    if (countryItem[0].showMarketingText) {
                        setOptInText(countryItem[0].marketingText)
                        setShowOptIn(true)
                    } else {
                        setOptInText('')
                        setShowOptIn(false)
                    }
                }
            }
            
            dispatchFieldChanges({ id: inputId, value: val })
            // HERE
            checkConnectedFields(inputId, val)
        }
    }

    const updateInputText = (id, value) => {
        const el = document.getElementById(id)
        if(el) {
            el.shadowRoot.getElementById('field').value = value
            el.shadowRoot.getElementById('field').dispatchEvent(new Event('input', { bubbles: true }))
        }
    }

    const updateDropdownOption = (id, valueSelector = null) => {
        const el = document.getElementById(id)
        
        if(el) {
            el.shadowRoot.querySelector('.control').dispatchEvent(new Event('mousedown'))
            
            if(valueSelector && valueSelector !== '') {
                el.shadowRoot.querySelector(`#${valueSelector}`).dispatchEvent(new Event('click'))
            } else {
                el.shadowRoot.querySelector('li:nth-child(1)').dispatchEvent(new Event('click'))
            }
        }
    }

    const handleTdsInputOnChange = useCallback( e => {
        const input = e.target
        const val = e.nativeEvent ? e.nativeEvent.target.value : e.detail
        const inputId = e.target.id
        
        if (e.target.dataset && Object.hasOwnProperty.call(e.target.dataset, 'for')) {
            let newValue = ''

            if (input.type === 'checkbox') {
                newValue = input.label
            }

            if (newValue !== '') {
                const destinationId = e.target.dataset.for
                const destinationField = document.querySelector(`#${destinationId}`)
                let arrValues = destinationField.value ? destinationField.value.split(' ; ') : []
                
                if ( input.type === 'checkbox' && val === true) {
                    arrValues.push(newValue)
                    
                } else {
                    arrValues = arrValues.filter(value => value !== newValue)
                }

                arrValues = [...new Set(arrValues)]
                
                if(destinationId === 'Survey_Month__c') {
                    if(arrValues.length < 2 || arrValues.length > 4) {
                        dispatchFieldErrorsChanges({ id: destinationId, value: 'Please choose 2 - 4 months' })
                    } else {
                        dispatchFieldErrorsChanges({ id: destinationId, value: null })
                    }
                }

                destinationField.value = arrValues.join(' ; ')
                dispatchFieldChanges({ id: destinationId, value: destinationField.value })
            }
        }

        if (inputId) {
            dispatchFieldChanges({ id: inputId, value: val })
            checkConnectedFields(inputId, val)
        }

        if (input.type === 'checkbox' && inputId === 'CopyUserData') {
            if(val === true) {
                updateInputText('Program_Lead_First_Name__c', document.querySelector('#Supplemental_Contact_First_Name__c').shadowRoot.querySelector('input').value)
                updateInputText('Program_Lead_Last_Name__c', document.querySelector('#Supplemental_Contact_Last_Name__c').shadowRoot.querySelector('input').value)
                updateInputText('Program_Lead_Title__c', document.querySelector('#Supplemental_Contact_Title__c').shadowRoot.querySelector('input').value)
                updateInputText('Program_Lead_Email__c', document.querySelector('#Supplemental_Contact_Email__c').shadowRoot.querySelector('input').value)
                updateDropdownOption('Primary_Contact_Country__c', document.querySelector('#Submitter_Contact_Country__c').value)

            } else {
                updateInputText('Program_Lead_First_Name__c', '')
                updateInputText('Program_Lead_Last_Name__c', '')
                updateInputText('Program_Lead_Title__c','')
                updateInputText('Program_Lead_Email__c', '')
                updateDropdownOption('Primary_Contact_Country__c', '')
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTdsRadioGroupOnChange = useCallback( e => {
        e.preventDefault()
        const val = e.nativeEvent ? e.nativeEvent.target.value : e.detail
        const inputId = e.target.id
        
        if (inputId) {
            dispatchFieldChanges({ id: inputId, value: val })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleInputFileOnChange = useCallback( e => {
        e.preventDefault()
        const val = e.target.files[0]
        const inputId = e.target.id
        if (inputId) {
            dispatchFieldChanges({ id: inputId, value: val })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // --- END OF CALLBACKS ---


    // --- CUSTOM METHODS ---
    const getListOfCountries = () => {
        fetch(process.env.GATSBY_COUNTRY_LIST_ENDPOINT).then( result => result.json()).then( result => {
            setCountryList(result)
        
        }).catch((error) => {
            setTimeout(() => {
                getListOfCountries()
            }, 5000)
        })
    }

    const checkConnectedFields = (currentField, newValue) => {
        const groupedFields = [
            [
                'Marketing_Contact_First_Name__c',
                'Marketing_Contact_Last_Name__c',
                'Marketing_Contact_Title__c',
                'Marketing_Contact_Email__c',
                'Marketting_Contact_Country__c'
            ],
            [
                'Current_or_Previous_Salesforce_Contact__c_Name',
                'Current_or_Previous_Salesforce_Contact__c_Title',
                'Current_or_Previous_Salesforce_Contact__c_Team',
                'Current_or_Previous_Salesforce_Contact__c_Email',
                'Current_or_Previous_Salesforce_Contact__c_Country'
            ]
        ]

        const group = groupedFields.find(group => group.find(item => item === currentField))

        if(group && Array.isArray(group)) {
            let otherFillledFields = false

            group.forEach( field => {
                const fieldEl = document.getElementById(field)
                if(fieldEl && field !== currentField) {
                    if(fieldEl.value && fieldEl.value !== '') {
                        otherFillledFields = true
                    }
                }
            })
            
            group.forEach( field => {
                const fieldEl = document.getElementById(field)
                
                if(fieldEl) {
                    if(newValue !== '') {
                        fieldEl.setAttribute('required', 'true')
                        if(fieldEl.nodeName === 'TDS-COMBOBOX' && !fieldEl.parentElement.querySelector('.u-input-label .u-required-simbol')) {
                            const requiredTextElement = document.createElement('span')
                            requiredTextElement.classList.add('u-required-simbol')
                            requiredTextElement.innerText = '*'
                            fieldEl.parentElement.querySelector('.u-input-label').prepend(requiredTextElement)
                        }
                    } else if( !otherFillledFields ) {
                        fieldEl.removeAttribute('required')
                        if(fieldEl.nodeName === 'TDS-COMBOBOX') {
                            fieldEl.parentElement.querySelector('.u-required-simbol').remove()
                        }
                    }
                }
            })
        }
    }

    const prepareFields = () => {
        // FILL TDS_COMBOBOX OPTIONS WHEN THEY ARE READY ON PAGE
        customElements.whenDefined("tds-combobox").then(() => {
            
            setComboOptions()

            document.querySelectorAll("tds-combobox").forEach( input => {
                if (input.dataset && !Object.hasOwnProperty.call(input.dataset, 'onChangeBinded')) {
                    input.dataset.onChangeBinded = true
                    // Add Event Listener
                    input.addEventListener('change', handleTdsComboboxOnChange)
                }
            })
        })

        customElements.whenDefined("tds-input").then(() => {
            document.querySelectorAll("tds-input").forEach( input => {
                if (input.type === 'text') {
                    input.value = inputValues[input.id]
                }

                if (input.type === 'checkbox') {
                    input.checked = inputValues[input.id] || false 
                }

                if (input.dataset && Object.hasOwnProperty.call(input.dataset, 'for')) {
                    let newValue = ''

                    if (input.type === 'checkbox') {
                        newValue = input.label
                    }

                    if (newValue !== '') {
                        const destinationField = document.querySelector(`#${input.dataset.for}`)
                        let arrValues = destinationField.value ? destinationField.value.split(';') : []
                        
                        if ( input.type === 'checkbox' && input.checked) {
                            if ( input.checked) {
                                arrValues.push(newValue)
                                
                            } else {
                                arrValues = arrValues.filter(value => value !== newValue)
                            }
                        }

                        arrValues = [...new Set(arrValues)]
                        destinationField.value = arrValues.join(';')
                    }
                }

                if (input.dataset && !Object.hasOwnProperty.call(input.dataset, 'onChangeBinded')) {
                    input.dataset.onChangeBinded = true
                    // Add Event Listener
                    input.addEventListener('change', handleTdsInputOnChange)
                }
            })
        })

        customElements.whenDefined("tds-radio-group").then(() => {
            document.querySelectorAll("tds-radio-group").forEach( input => {
                if (input.dataset && !Object.hasOwnProperty.call(input.dataset, 'onChangeBinded')) {
                    input.dataset.onChangeBinded = true
                    // Add Event Listener
                    input.addEventListener('change', handleTdsRadioGroupOnChange)
                }
            })
        })

        const fileUploader = document.querySelector('#Upload_Logo__c')
        if (fileUploader && !Object.hasOwnProperty.call(fileUploader.dataset, 'onChangeBinded')) {
            fileUploader.dataset.onChangeBinded = true
            // Add Event Listener
            fileUploader.addEventListener('change', handleInputFileOnChange)
        }
    }

    const setComboOptions = () => {
        //customElements.whenDefined("tds-combobox").then(() => {
            
            const dropdownsOptions = {
                ...FormUtils.getDropdownsOptions(), 
            }

            Object.keys(dropdownsOptions).forEach( combo => {
                const comboEl = document.querySelector(`#${combo}`)
                if (comboEl) {
                    comboEl.options = dropdownsOptions[combo].map(item => {return {label: item, value: item}})
                    comboEl.value = inputValues[combo]
                }
            })

            const listData = {
                "country": countryList.map(item => item.label)
            }

            document.querySelectorAll('[data-list]').forEach(combo => {
                const listType = combo.dataset.list;
                if(Object.hasOwnProperty.call(listData, listType)) {
                    combo.options = listData[listType].map(item => {return {label: item, value: item}})
                    combo.value = inputValues[combo]
                }
            })
        //})
    }

    const handleNext = () => {
        if (stepNumber === 0 || (stepNumber > 0 && !btnNextDisabled)) {
            //setBtnNextDisabled(true)
            const nextStep = stepNumber + 1
            const newStepCompleted = lastStepCompleted < stepNumber ? stepNumber : lastStepCompleted
            setLastStepCompleted(newStepCompleted)
            setStepNumber(nextStep <= stepNames.length ? nextStep : stepNames.length)
            // TODO: focus on first field
            if (window) {
                window.scrollTo(0, 0)
            }
        }
    }

    const fakeStepNavigation = (stepNum) => {
        setStepNumber(stepNum <= stepNames.length ? stepNum : stepNames.length)
    }

    const goToSuccess = () => {
        setShowSuccess(true)
    }

    const handleCancel = () => {
        setStepNumber(0)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!btnSubmitDisabled) {
            setBtnSubmitDisabled(true)
            setLoading(true)

            //grecaptcha.ready(() => {
                //grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY, {action: 'submit'}).then( (token) => {
                    submitData()
                //})
            //})
        }
    }

    const handleRecaptchaFail = () => {
        setRecaptchaToken('')
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email)
    }

    const isValidUrl = (string) => {
        const regex = /^(http|https):\/\/[^ "]+\.[^ "]+$/
        return regex.test(string)
    }

    const checkToContinue = (recheck = false) => {
        const currentContainer = document.querySelector(`#formStep${stepNumber}`)
        
        if (currentContainer) {
            const requiredFields = currentContainer.querySelectorAll('[required]')
            const fieldsToValidate = currentContainer.querySelectorAll('[data-validate]')
            let allFilled = true
            let hasAnyError = false
            
            requiredFields.forEach( field => {
                if (allFilled) {
                    let value = ''
                    
                    if (field.tagName === 'TDS-INPUT') {
                        if (field.type === "text" || field.type === "email") {
                            value = field.shadowRoot.querySelector("input").value

                        } else if (field.type === "checkbox") {
                            value = field.shadowRoot.querySelector("input").checked ? 'checked' : ''
                        }
                    
                    } else if (field.tagName === 'TDS-COMBOBOX') {
                        value = field.value
                    
                    } else if (field.tagName === 'TDS-RADIO-GROUP') {
                        value = field.value.toLowerCase() === 'yes' ? true : false
                    
                    } else if (field.tagName === 'INPUT' && (field.type === 'hidden' || field.type === 'file')) {
                        value = field.value
                    }

                    if (value === '') {
                        allFilled = false
                    }
                }
            })

            fieldsToValidate.forEach( field => {
                let valid = true
                
                if (field.tagName === 'TDS-INPUT') {
                    const value = field.shadowRoot.querySelector("input").value
                    
                    if (field.type === "text" || field.type === "email") {

                        if(value !== '' && field.dataset && Object.hasOwnProperty.call(field.dataset, 'limitMax') && parseInt(field.dataset.limitMax) > 0 && value.length > parseInt(field.dataset.limitMax)) {
                            dispatchFieldErrorsChanges({ id: field.id, value: `Max characters allowed: ${parseInt(field.dataset.limitMax)}` })
                            valid = false
                        
                        }else if(value !== '' && field.dataset && Object.hasOwnProperty.call(field.dataset, 'validate') && field.dataset.validate === 'email' && !isValidEmail(value)) {
                            dispatchFieldErrorsChanges({ id: field.id, value: 'Email not valid' })
                            valid = false
                        
                        } else if(value !== '' && field.dataset && Object.hasOwnProperty.call(field.dataset, 'validate') && field.dataset.validate === 'url' && !isValidUrl(value)) {
                            dispatchFieldErrorsChanges({ id: field.id, value: 'Url not valid' })
                            valid = false
                        
                        } else if(value !== '' && field.dataset && Object.hasOwnProperty.call(field.dataset, 'validate') && field.dataset.validate === 'integer' && (!Number.isInteger(parseInt(value)) || parseInt(value).toString().length !== value.length) ) {
                            dispatchFieldErrorsChanges({ id: field.id, value: 'Number not valid' })
                            valid = false
                        
                        } else {
                            dispatchFieldErrorsChanges({ id: field.id, value: null })
                        }
                    }
                
                }

                if (!valid) {
                    allFilled = false
                }
            })

            const existingErrors = Object.keys(fieldErrors).filter(id => {
                const fieldErrorValue = fieldErrors[id]
                return fieldErrorValue !== undefined && fieldErrorValue !== null && fieldErrorValue !== ''
            })

            if(existingErrors.length > 0) {
                hasAnyError = true
            }

            if (allFilled && !hasAnyError) {
                setBtnNextDisabled(false)
                
                if(stepNumber === stepNames.length && recaptchaToken !== '') {
                   setBtnSubmitDisabled(false) 
                } else {
                    setBtnSubmitDisabled(true)
                }
            
            } else {
                setBtnNextDisabled(true)
                
                if(stepNumber === stepNames.length ) {
                    setBtnSubmitDisabled(true)
                }

                // if(recheck) {
                //     //setTimeout(() => {
                //         //checkToContinue()
                //     //}, 2000)
                // }
            }
        }
    }

    const submitData = async () => {
        let uploadErrorCode = null
        let uploadErrorMessage = null
        let uploadedImageName = uploadedImage

        // Set the recaptchaRef object here because it lose the reference during the "fetch"
        const recaptchaObj = recaptchaRef.current

        if (enableUploadImage && !uploadedImage) {
            // Upload image to S3 with our API
            const input = document.getElementById('Upload_Logo__c')
            const data = new FormData()
            data.append('file', input.files[0])

            const uploaderOptions = {
                method: 'POST',
                headers: {"g-captcha-response": recaptchaToken + "###;v2"},
                body: data
            }

            // Upload Image using the custom S3_Image_Uploader API
            await fetch(process.env.GATSBY_IMAGE_UPLOADER_ENDPOINT, uploaderOptions).then((res) => res.json()).then((response) => {
                
                if(response.status === 'success') {
                    uploadedImageName = response.data.imageName
                    setUploadedImage(uploadedImageName)
                
                } else {
                    uploadedImageName = null
                    uploadErrorCode = response.errorCode
                    //uploadErrorMessage = response.errorMessage || null
                    const maxSize = response.maxSize ? response.maxSize/1024 : '';
                    uploadErrorMessage = (uploadErrorCode == 0 || uploadErrorCode == 5) ? `File is too large (${maxSize}kb max)` : response.errorMessage;
                    
                    setErrorMessage(`Image was NOT uploaded, ${uploadErrorMessage}`)
                    setBtnSubmitDisabled(false)
                }

            }).catch((error) => {
                uploadErrorMessage = `Can't connect to the endpoint. ${error}`
                setErrorMessage(`Image was NOT uploaded, ${uploadErrorMessage}`)
                setBtnSubmitDisabled(false)
            })
        
        } else if(!enableUploadImage) {
            // set uploadedImageName with a test image name
            uploadedImageName = '224e396e-eb93-4c5c-8ba8-deebf3e5868a'
            setUploadedImage(uploadedImageName)
        }

        if(!uploadedImageName) {
            recaptchaObj.props.grecaptcha.reset()

        } else {
            setErrorMessage(null)
            const countryItem = countryList.filter(item => item.label === inputValues.Program_Country__c)
            let countryCode = ''
            const currentContactArr = []
            let currentContact = ''
            const RegisteredOrganizationAddressArr = []
            let RegisteredOrganizationAddress = ''
            let optInCheck = false
            let ContactInformationConsent = false

            // MERGE CURRENT CONTACT INFO INTO ONE FIELD
            const currentContactName = inputValues.Current_or_Previous_Salesforce_Contact__c_Name
            const currentContactTitle = inputValues.Current_or_Previous_Salesforce_Contact__c_Title
            const currentContactTeam = inputValues.Current_or_Previous_Salesforce_Contact__c_Team
            const currentContactEmail = inputValues.Current_or_Previous_Salesforce_Contact__c_Email
            const currentContactCountry = inputValues.Current_or_Previous_Salesforce_Contact__c_Country

            if(currentContactName) {
                currentContactArr.push(currentContactName)
            }
            if(currentContactTitle) {
                currentContactArr.push(currentContactTitle)
            }
            if(currentContactTeam) {
                currentContactArr.push(currentContactTeam)
            }
            if(currentContactEmail) {
                currentContactArr.push(currentContactEmail)
            }
            if(currentContactCountry) {
                currentContactArr.push(currentContactCountry)
            }

            currentContact = currentContactArr.length > 0 ? currentContactArr.join(', ') : null
            ////

            // MERGE ORGANIZATION ADDRESS INTO ONE FIELD
            const RegisteredOrganizationAddressStreet = inputValues.Registered_Organization_Address_street
            const RegisteredOrganizationAddressCity = inputValues.Registered_Organization_Address_city
            const RegisteredOrganizationAddressState = inputValues.Registered_Organization_Address_state

            if(RegisteredOrganizationAddressStreet) {
                RegisteredOrganizationAddressArr.push(RegisteredOrganizationAddressStreet)
            }
            if(RegisteredOrganizationAddressCity) {
                RegisteredOrganizationAddressArr.push(RegisteredOrganizationAddressCity)
            }
            if(RegisteredOrganizationAddressState) {
                RegisteredOrganizationAddressArr.push(RegisteredOrganizationAddressState)
            }

            RegisteredOrganizationAddress = RegisteredOrganizationAddressArr.length > 0 ? RegisteredOrganizationAddressArr.join(', ') : null
            ////
            
            if (countryItem.length === 1) {
                countryCode = countryItem[0].value
            }

            if(!showOptIn || inputValues.Opt_In_Check__c === true) {
                optInCheck = true
            }

            if(!ContactInformationConsent || inputValues.Contact_Information_Consent__c === true) {
                ContactInformationConsent = true
            }

            //Program_Region__c: inputValues.Program_Region__c,
            //Program_Region__c: 'BAD VALUE',

            const formHandlerBody = {
                Program_Lead_First_Name__c: inputValues.Program_Lead_First_Name__c,
                Program_Lead_Last_Name__c: inputValues.Program_Lead_Last_Name__c,
                Program_Lead_Title__c: inputValues.Program_Lead_Title__c,
                Program_Lead_Email__c: inputValues.Program_Lead_Email__c,
                Marketing_Contact_First_Name__c: inputValues.Marketing_Contact_First_Name__c,
                Marketing_Contact_Last_Name__c: inputValues.Marketing_Contact_Last_Name__c,
                Marketing_Contact_Title__c: inputValues.Marketing_Contact_Title__c,
                Marketing_Contact_Email__c: inputValues.Marketing_Contact_Email__c,
                Supplemental_Contact_First_Name__c: inputValues.Supplemental_Contact_First_Name__c,
                Supplemental_Contact_Last_Name__c: inputValues.Supplemental_Contact_Last_Name__c,
                Supplemental_Contact_Title__c: inputValues.Supplemental_Contact_Title__c,
                Supplemental_Contact_Email__c: inputValues.Supplemental_Contact_Email__c,
                Organization_Name__c: inputValues.Organization_Name__c,
                Organization_Website_Link__c: inputValues.Organization_Website_Link__c,

                Type_of_Organization__c: inputValues.Type_of_Organization__c,
                Landing_Page_Description__c: inputValues.Landing_Page_Description__c,
                Developed_Training_using_Salesforce__c: inputValues.Developed_Training_using_Salesforce__c,     
                Accomplishments_by_Workforce_Program__c: inputValues.Accomplishments_by_Workforce_Program__c,
                Certified_for_Training_Support__c: inputValues.Certified_for_Training_Support__c,
                Provide_Periodic_reports_to_Salesforce__c: inputValues.Provide_Periodic_reports_to_Salesforce__c,
                Cost_To_Participant__c: inputValues.Cost_To_Participant__c,
                Survey_Month__c: inputValues.Survey_Month__c,
                Primary_Group_Served__c: inputValues.Primary_Group_Served__c,
                Secondary_Group_Served__c: inputValues.Secondary_Group_Served__c,
                Group_Served_Details__c: inputValues.Group_Served_Details__c,
                Volunteers__c: inputValues.Volunteers__c,
                Volunteer_Url__c: inputValues.Volunteer_Url__c,
                Partnering_consulting__c: inputValues.Partnering_consulting__c,
                Organisation_Directory_Name__c: inputValues.Organisation_Directory_Name__c,
                Program_Length__c: inputValues.Program_Length__c,
                Program_Path__c: inputValues.Program_Path__c,
                Description_for_Recruitment_Purpose__c: inputValues.Description_for_Recruitment_Purpose__c,
                Description_for_Employer_Outreach__c: inputValues.Description_for_Employer_Outreach__c,
                Is_your_program_available_virtually__c: inputValues.Is_your_program_available_virtually__c,
                Program_Province__c: inputValues.Program_Province__c,
                Languages_used_program_in__c: inputValues.Languages_used_program_in__c,
                Program_Link__c: inputValues.Program_Link__c,
                Is_Term_Accepted__c: inputValues.Is_Term_Accepted__c,
                Program_Region__c: inputValues.Program_Region__c,
                Current_Salesforce_Partner_or_customer__c: inputValues.Current_Salesforce_Partner_or_customer__c === 'Yes' ? true : false,
                Instructorled_Training_Provided__c: inputValues.Instructorled_Training_Provided__c === 'Yes' ? true : false,
                Is_there_a_cost_to_Enroll__c: inputValues.Is_there_a_cost_to_Enroll__c === 'Yes' ? true : false,
                Program_include_Certification__c: inputValues.Program_include_Certification__c === 'Yes' ? true : false,
                Is_Include_Program_Curriculum__c: inputValues.Is_Include_Program_Curriculum__c === 'Yes' ? true : false,
                Is_Mentorship_Included__c: inputValues.Is_Mentorship_Included__c === 'Yes' ? true : false,
                Is_Case_Study__c: inputValues.Is_Case_Study__c === 'Yes' ? true : false,
                Is_Include_Experience_Project__c: inputValues.Is_Include_Experience_Project__c === 'Yes' ? true : false,
                Is_Placement_Support__c: inputValues.Is_Placement_Support__c === 'Yes' ? true : false,
                Is_Career_Coaching__c: inputValues.Is_Career_Coaching__c === 'Yes' ? true : false,

                // new fields
                Leaner_Served_in_Last_Year__c: inputValues.Leaner_Served_in_Last_Year__c,
                Graduates_Count_of_Last_Year__c: inputValues.Graduates_Count_of_Last_Year__c,
                Submitter_Contact_Country__c: inputValues.Submitter_Contact_Country__c,
                Primary_Contact_Country__c: inputValues.Primary_Contact_Country__c,
                Marketting_Contact_Country__c: inputValues.Marketting_Contact_Country__c,
                Contact_Information_Consent__c: ContactInformationConsent,
                Registered_Organization_Country__c: inputValues.Registered_Organization_Country__c,
                /////

                Atleast_60_economically_underserved__c: inputValues.Atleast_60_economically_underserved__c === 'Yes' ? true : false,
                Track_demographic_data_for_participants__c: inputValues.Track_demographic_data_for_participants__c === 'Yes' ? true : false,
                handlerName: "WFD_Form_Handler__c",
                Opt_In_Check__c: optInCheck,
                Upload_Logo__c: uploadedImageName,
                Program_Country__c: inputValues.Program_Country__c,
                Program_State__c: countryCode === 'US' ? inputValues.Program_State__c : '',

                Current_or_Previous_Salesforce_Contact__c: currentContact,
                Registered_Organization_Address__c: RegisteredOrganizationAddress,
            }
    
            const formHandlerOptions = {
                method: 'POST',
                mode: 'cors',
                referrerPolicy: 'origin-when-cross-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'origin, X-Custom-Header',
                    'Access-Control-Allow-Method': 'POST',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(formHandlerBody)
            }

            let formHandlerResponse;
            let formHandlerData;

            try {
                formHandlerResponse = await fetch(process.env.GATSBY_DRM_ENDPOINT, formHandlerOptions)
                formHandlerData = await formHandlerResponse.json()
            
            } catch (error) {
                const errorMessage = 'There was an error sending the form, please try again'
                setErrorMessage(errorMessage)
                setBtnSubmitDisabled(false)
                setLoading(false)
            }
    
            if (formHandlerData?.status === 'success' && formHandlerData?.errorCode === null) {
                goToSuccess()
                
            } else {
                const errorMessage = formHandlerData.errorMessage
                setErrorMessage(errorMessage)
                setBtnSubmitDisabled(false)
                setLoading(false)
            }
        }
    }
    // --- END OF CUSTOM METHODS

    return (
        <>
            { stepNumber === 0 && !showSuccess && 
                <FormIntro handleNext={handleNext} goToSuccess={goToSuccess} formActive={formActive} /> 
            }

            {stepNumber > 0 && !showSuccess && (
                <>
                    <div className="slds-p-top_large">
                        {stepNumber > 0 && (
                            <ul className="tablist">
                                {stepNames.map((step, index) => {
                                    return (
                                        <li 
                                            key={`stepProgress-item_${index}`} 
                                            role="presentation" 
                                            className={`tab ${stepNumber === (index+1) ? 'tab--active' : ''} ${lastStepCompleted < index ? 'tab--disabled' : 'tab--enabled'}`}>
                                            
                                            <button 
                                                disabled={lastStepCompleted < index} 
                                                className="tab__button"
                                                role="tab"
                                                aria-controls="tabpanel"
                                                data-value="1"
                                                title={`${(index+1)}. ${step}`}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    if(lastStepCompleted >= index) {
                                                        fakeStepNavigation(index+1)
                                                    }
                                                }}>
                                                
                                                <span>{`${(index+1)}. ${step}`}</span>
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                    </div>

                    <tm-section-a background="light">
                        <div className="slds-grid slds-wrap slds-grid_align-start slds-p-bottom_large">
                            <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-horizontal_small slds-p-top_large">
                                <div className="u-font-size-8"><b>{stepNames[stepNumber-1]}</b></div>
                                <div className="u-font-size-3 slds-p-top_small u-color-red">Required*</div>
                            </div>
                            <div className="slds-size_1-of-1 slds-medium-size_5-of-12 slds-p-left_small slds-p-right_x-large">
                                <div className="slds-grid slds-wrap slds-grid_align-end">
                                    <div className="slds-size_1-of-2 slds-p-vertical_small u-font-size-3 slds-text-align_left">
                                        <b>Progress</b>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-p-vertical_small slds-text-align_right">
                                        <div className="u-font-size-3">Page {stepNumber} of 4</div>
                                    </div>
                                </div>
                                <tds-progress-bar progress={stepProgress} animation="on"></tds-progress-bar>
                            </div>
                        </div>

                        <div style={{ display: stepNumber === 1 ? 'block' : 'none' }}>
                            <FormStep1 inputValues={inputValues} fieldErrors={fieldErrors} />
                        </div>

                        <div style={{ display: stepNumber === 2 ? 'block' : 'none' }}>
                            <FormStep2 inputValues={inputValues} fieldErrors={fieldErrors} />
                        </div>
                        
                        <div style={{ display: stepNumber === 3 ? 'block' : 'none' }}>
                            <FormStep3 inputValues={inputValues} fieldErrors={fieldErrors} />
                        </div>

                        <div style={{ display: stepNumber === 4 ? 'block' : 'none' }}>
                            <FormStep4 
                                recaptchaRef={recaptchaRef}
                                inputValues={inputValues} 
                                fieldErrors={fieldErrors} 
                                setRecaptchaToken={setRecaptchaToken} 
                                handleRecaptchaFail={handleRecaptchaFail} 
                                showOptIn={showOptIn} 
                                optInText={optInText} 
                            /> 
                        </div>

                        <div style={{ display: errorMessage ? 'block' : 'none' }}>
                            <div className="slds-container_large slds-container_center slds-text-color_error slds-text-align_center">{errorMessage}</div>
                        </div>
                        
                        <div className="slds-container_large slds-container_center slds-p-bottom_large">
                            <div className="slds-p-vertical_x-large slds-grid slds-grid_align-end slds-grid_vertical-align-center">

                                <span style={{display: loading ? 'inline-block' : 'none'}}>
                                    <tds-spinner size="small"></tds-spinner>
                                </span>

                                <span className="slds-p-horizontal_x-large">
                                    <tds-button onClick={handleCancel}>Cancel</tds-button>
                                </span>
                                
                                {(stepNumber < stepNames.length && (
                                    <tds-button id="nextBtn" variant="brand" disabled={btnNextDisabled} onClick={handleNext}>Next</tds-button>
                                )) || (
                                    <tds-button variant="success" disabled={btnSubmitDisabled} onClick={handleSubmit}>Submit</tds-button>
                                )}
                                
                            </div>
                        </div>
                        
                    </tm-section-a>      
                </>
            )}
            
            { showSuccess && <FormSuccess /> }
        </>
    )
}

export default FormSteps
